import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

export default class extends Controller {

    static targets = ["chart"]

    connect() {
        var margin = {top: 0, right: 0, bottom: 0, left: 0}
        var width = 696 - margin.left - margin.right
        var height = 480 - margin.top - margin.bottom

        var data = JSON.parse(this.chartTarget.dataset.data)
        // console.log("data", data)

        var svg = d3.select("svg#compound-interest-calculator")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)

        // X Axis
        var x = d3.scaleBand()
            .domain(data.statements.map(d => d.year))
            .range([0, width])
            .padding(0.01)
        
        // Y Axis
        var y = d3.scaleLinear()
            .domain([0, d3.max(data.statements, (d) => d.end_of_year_balance )])
            .range([height, 0])

        svg.selectAll("bars")
            .data(data.statements)
            .enter()
            .append("rect")
            .attr("x", (d) => x(d.year))
            .attr("y", (d) => y(d.end_of_year_balance))
            .attr("width", x.bandwidth())
            .attr("height", (d) => height - y(d.end_of_year_balance))
            .attr("fill", "#aaa")

        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x))

        svg.append("g")
            .call(d3.axisLeft(y))
            .selectAll("text")
                .attr("x", "8")
                .style("text-anchor", "start")
    }

}
