import { Controller } from "@hotwired/stimulus"
import { randomInt, randomFloat } from "./canvas/helpers.js"
import PixelType from "./typefaces/pixeltype.js"
import seedrandom from "seedrandom"

export default class extends Controller {

    static targets = [ "canvas" ]

    backgroundColour = "rgb(250, 250, 250)"

    crosshairColour = "rgba(0, 0, 0, 0.2)"
    crosshairSize = 6
    crosshairwidth = 0.5
    
    gridLineColour = "rgba(0, 0, 0, 0.02)"
    gridLineWidth = 1

    gridSpacing = 120
    gridLinePadding = 0

    connect() {
        this.canvas = this.canvasTarget

        window.addEventListener("load", () => {
            // Hack to ensure the grid is drawn after the box layout has been calculated
            setTimeout(() => { this.draw()}, 1)
        })

        window.addEventListener("resize", () => {
            this.draw()
        })

        window.addEventListener("section-resize", (e) => {
            this.draw()
        })

        this.draw() // Initial draw to prevent flicker
    }

    draw() {
        this.canvas.width  = document.body.clientWidth
        this.canvas.height = Math.max(document.body.clientHeight, window.innerHeight)
        this.context = this.canvas.getContext("2d")

        this.context.fillStyle = this.backgroundColour
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height)
        
        this.setupGrid()

        this.drawGridLines()
        this.drawCrosshairs()
        // this.drawGridSquares()

        this.drawNoise()

        this.drawFooterMotif()

        this.drawPageMetaText()
    }

    setupGrid() {
        this.points = new Array();
        var x = 0;
        var y = 0;

        while((y - this.gridSpacing) < this.canvas.height) {
            while((x - this.gridSpacing) < this.canvas.width) {
                this.points.push({"x": x, "y": y});
                x = x + this.gridSpacing;
            }
            x = 0;
            y = y + this.gridSpacing;
        }
    }

    drawGridLines() {
        this.points.forEach((point) => {
            this.context.save()

            this.context.beginPath()

            this.context.translate(point.x, point.y)

            this.context.lineWidth = this.gridLineWidth
            this.context.strokeStyle = this.gridLineColour

            this.context.moveTo(this.gridLinePadding, this.gridLinePadding)
            this.context.lineTo(this.gridSpacing - this.gridLinePadding, this.gridLinePadding)
            this.context.lineTo(this.gridSpacing - this.gridLinePadding, this.gridSpacing - this.gridLinePadding)
            this.context.lineTo(this.gridLinePadding, this.gridSpacing - this.gridLinePadding)
            this.context.lineTo(this.gridLinePadding, this.gridLinePadding)

            this.context.closePath()
            this.context.stroke()

            this.context.restore()
        })
    }

    drawCrosshairs() {
        this.points.forEach((point) => {
            this.context.save()
            this.context.translate(point.x, point.y)
            
            this.context.beginPath()
            this.context.strokeStyle = this.crosshairColour
            this.context.lineWidth = this.crosshairwidth
    
            this.context.moveTo(0, this.crosshairSize)
            this.context.lineTo(0, 0 - this.crosshairSize)
    
            this.context.moveTo(this.crosshairSize, 0)
            this.context.lineTo(0 - this.crosshairSize, 0)
    
            this.context.closePath()
            this.context.stroke()
            
            this.context.restore()
        })
    }

    drawGridSquares() {
        this.points.forEach((point) => {
            // this.context.fillStyle = `rgba(${randomInt(0, 255)}, ${randomInt(0, 255)}, ${randomInt(0, 255)}, ${randomFloat(0, 0.1)})`
            this.context.fillStyle = `rgba(150, 150, 150, ${randomFloat(0, 0.1)})`
            this.context.fillRect(point.x, point.y, this.gridSpacing, this.gridSpacing)
        })
    }

    drawNoise() {
        let rng = seedrandom('hello');
        // console.log(parseInt(rng() * 255))
        //return Math.random() * (max-min) + min


        // console.log(rng());                  // Always 0.9282578795792454
        let noiseSize = 12
        let xCount = this.canvas.width / noiseSize
        let yCount = this.canvas.height / noiseSize

        for (let x = 0; x <= xCount; x++) {
            for (let y = 0; y <= yCount; y++) {
                // console.log(x, y)
                let grey = parseInt(rng() * (255)) //randomInt(0, 255)
                // console.log(grey)
                this.context.fillStyle = `rgba(${grey}, ${grey}, ${grey}, 0.02)`
                this.context.fillRect(x * noiseSize, y * noiseSize, noiseSize, noiseSize)
            }
        }
    }

    drawFooterMotif() {
        let footer = document.getElementById("footer")
        let footerRect = footer.getBoundingClientRect()
        this.context.fillStyle = "rgba(10, 20, 30, 0.1)"
        this.context.fillRect(footerRect.x + 12, (footerRect.y -120) + 12, footerRect.width - 24, footerRect.height - 24)
    }

    drawPageMetaText() {
        let largePixelType = new PixelType(this.context, {pixelSize: ((120/100) * 4)})
        let smallPixelType = new PixelType(this.context, {pixelSize: ((120/100) * 2)})
        largePixelType.render(960 + 12, 120 + 12, "maniacalrobot.co.uk")

        smallPixelType.render(960 + 12, 120 + 44, "Photographer, Dreamweaver, Visionary,")
        smallPixelType.render(960 + 12, 120 + 60, "Webmaster, plus Software Engineer")
        smallPixelType.render(960 + 12, 120 + 76, "@zendesk. Previously @heroku, @kyan")
        smallPixelType.render(960 + 12, 120 + 92, "based in Guildford, Surrey, UK.")
    }
}