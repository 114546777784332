import { Controller } from "@hotwired/stimulus"
import { randomFloat, randomInt, randomElement } from "./helpers"

class Tile {

    colour = "purple"

    constructor(grid, x, y) {
        this.grid = grid
        this.x = x
        this.y = y
        this.width = 18
        this.height = 18
    }

    draw(context) {
        context.fillStyle = this.colour
        context.fillRect(this.width * this.x, this.height * this.y, this.width, this.height)
    }

    neighbours() {
        let arr = []

        // Left
        if (this.x > 0) {
            arr.push(this.grid[this.x - 1][this.y])
        }
        // Right
        if (this.x < this.grid.length - 1) {
            arr.push(this.grid[this.x + 1][this.y])
        }
        // Up
        if (this.y > 0) {
            arr.push(this.grid[this.x][this.y - 1])
        }
        // Down
        if (this.y < this.grid[0].length - 1) {
            arr.push(this.grid[this.x][this.y + 1])
        }

        return arr
    }
}

class BlankTile extends Tile {
    possibleTiles = []
    colour = "white"

    allowedNeighbours() {
        return [...new Set(this.possibleTiles.map((tile) => {
            return tile.allowedNeighbours()
        }).flat())]
    }

    collapseTo() {
        return randomElement(this.possibleTiles)
    }

}

class SeaTile extends Tile {
    colour = "blue"

    allowedNeighbours() {
        return ["SeaTile", "SandTile"]
    }
}

class SandTile extends Tile {
    colour = "yellow"

    allowedNeighbours() {
        return ["SeaTile", "SandTile", "GrassTile"]
    }
}

class GrassTile extends Tile {
    colour = "green"

    allowedNeighbours() {
        return ["SandTile", "GrassTile", "ForestTile", "MountainTile"]
    }
}

class ForestTile extends Tile {
    colour = "darkgreen"

    allowedNeighbours() {
        return ["GrassTile", "ForestTile"]
    }
}

class MountainTile extends Tile {
    colour = "grey"

    allowedNeighbours() {
        return ["GrassTile", "MountainTile"]
    }
}

class WaveFunctionCollapseController extends Controller {

    static targets = ["canvas"]

    async connect() {
        console.log("Hello from wavefunctioncollapse connect")

        this.canvas = this.canvasTarget
        this.context = this.canvas.getContext("2d")

        this.canvas.width = 720
        this.canvas.height = 720

        this.gridWidth = 40
        this.gridHeight = 40
        this.grid = new Array();

        this.setup()
        setTimeout(() => {
            this.collapseGrid()
        }, 5)

    }

    setup() {
        for(var i = 0; i < this.gridWidth; i++) {
            this.grid[i] = new Array();
            for(var j = 0; j < this.gridHeight; j++) {
                this.grid[i][j] = new BlankTile(this.grid, i, j)
                // Hack
                // Simple weighting for choosing tiles
                this.grid[i][j].possibleTiles = [
                    new SeaTile(this.grid, i, j),

                    new SandTile(this.grid, i, j),
                    
                    new GrassTile(this.grid, i, j),
                    new GrassTile(this.grid, i, j),
                    new GrassTile(this.grid, i, j),
                    new GrassTile(this.grid, i, j),

                    new ForestTile(this.grid, i, j),

                    new MountainTile(this.grid, i, j),
                    new MountainTile(this.grid, i, j),
                ]
            }
        }
        // this.grid[0][0] = randomElement(this.grid[0][0].possibleTiles)
    }

    build() {
    }

    propagate(fromTile, toTile) {
        
        // update neighbours
        // call propagate on neighbours

        // console.log("Propagating from: " + fromTile.x + ", " + fromTile.y)
        // console.log("Propagating to: " + toTile.x + ", " + toTile.y)
        
        // find neighbours
        toTile.neighbours().filter((neighbourTile) => {
            return neighbourTile instanceof BlankTile && neighbourTile != fromTile
        }).forEach((neighbourTile) => {
            // keep track of initial length
            let initialLength = neighbourTile.possibleTiles.length

            // console.log("Propagating to neighbour: " + neighbourTile.x + ", " + neighbourTile.y)
            // console.log("Original Tiles: ")
            // console.log(neighbourTile.possibleTiles)

            // update neighbours
            neighbourTile.possibleTiles = neighbourTile.possibleTiles.filter((possibleTile) => {
                return toTile.allowedNeighbours().includes(possibleTile.constructor.name)
            })

            // console.log("Assigned Tiles: ")
            // console.log(neighbourTile.possibleTiles)

            // if the length has changed, propagate
            if (initialLength != neighbourTile.possibleTiles.length) {
                // window.requestAnimationFrame(this.propagate(toTile, neighbourTile))
                this.propagate(toTile, neighbourTile)
            }
        })
    }

    collapseGrid() {
        // find tile with least entropy
        // collapse tile
        // propagate constraints


        // Speed improvement if we keep track of which blank tiles have been updated, and remove collapsed tiles from the list

        // Find all blank tiles and sort by entropy (simple length of possible tiles)
        let tilesToCollapse = this.grid.flat().filter((tile) => {
            return tile instanceof BlankTile
        }).sort((a, b) => {
            return a.possibleTiles.length - b.possibleTiles.length
        })

        if (tilesToCollapse.length != 0) {
            let tileToCollapse = tilesToCollapse[0]
            this.grid[tileToCollapse.x][tileToCollapse.y] = this.grid[tileToCollapse.x][tileToCollapse.y].collapseTo()
            this.propagate(this.grid[tileToCollapse.x][tileToCollapse.y], this.grid[tileToCollapse.x][tileToCollapse.y])
            this.draw()
            
            setTimeout(() => {
                this.collapseGrid()
            })
        } else {
            return
        }

    }

    draw() {
        this.grid.forEach((row, rowIndex) => {
            row.forEach((tile, tileIndex) => {
                tile.draw(this.context)
            })
        })
    }

    logGridStats() {
        let stats = {}
        this.grid.forEach((row, rowIndex) => {
            row.forEach((tile, tileIndex) => {
                if (tile.constructor.name in stats) {
                    stats[tile.constructor.name] += 1
                } else {
                    stats[tile.constructor.name] = 1
                }
            })
        })
        console.log(stats)
    }
}

export default WaveFunctionCollapseController