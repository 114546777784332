import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    // static targets = ["extension"]
    static targets = ["tab", "panel"]

    connect() {
        console.log("TabsController connected")
        this.showTab(0)
    }

    change(e) {
        console.log("show tab: ", this.tabTargets.indexOf(e.target.parentNode))
        this.showTab(this.tabTargets.indexOf(e.target.parentNode))
    }

    showTab(index) {
        console.log("showTab: ", index)
        this.panelTargets.forEach((element, i) => {
          if (i == index) {
            element.classList.remove('hidden')
          } else {
            element.classList.add('hidden')
          }
        })
    }

    // expand(event) {
    //     event.preventDefault();
    //     event.target.classList.add("hidden")
    //     this.extensionTarget.classList.remove("hidden")
    // }

    // collapse(event) {
    //     event.preventDefault();
    //     this.extensionTarget.classList.add("hidden")
    // }
}






// Controller {

//     static targets = ["tab", "tabPanel"]

//     initialize() {
//       this.showTab()
//     }

//     change(e) {
//       this.index = this.tabTargets.indexOf(e.target.parentNode)
//       this.showTab(this.index)
//     }

//     showTab() {
//       this.tabPanelTargets.forEach((el, i) => {
//         if (i == this.index) {
//           el.classList.remove('is-hidden')
//         } else {
//           el.classList.add('is-hidden')
//         }
//       })

//       this.tabTargets.forEach((el, i) => {
//         if (i == this.index) {
//           el.classList.add('is-active')
//         } else {
//           el.classList.remove('is-active')
//         }
//       })
//     }

//     get index() {
//       return parseInt(this.data.get('index'))
//     }

//     set index(value) {
//       this.data.set("index", value)
//       this.showTab()
//     }
//   })