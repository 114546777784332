import { Controller } from "@hotwired/stimulus"
import * as d3 from "d3";

export default class extends Controller {

    static targets = ["chart"]

    connect() {
        var width = 480
        var height = 240

        var data = JSON.parse(this.chartTarget.dataset.data)
        console.log("data", data)

        // var links = JSON.parse(this.svgTarget.dataset.links)
        // console.log("links", links)

        var svg = d3.select("svg#stats-requests-visits")
            // .attr("viewBox", [0, 0, width, height])
            .attr("width", width)
            .attr("height", height)

        var x = d3.scaleBand()
            .domain(data.map(d => d.date))
            .range([0, width])
            .padding(0.01)
        
        var y = d3.scaleLinear()
            .domain([0, d3.max(data, (d) => d.value)])
            .range([height, 0])

        svg.selectAll("bars")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d) => x(d.date))
            .attr("y", (d) => y(d.value))
            .attr("width", x.bandwidth())
            .attr("height", (d) => height - y(d.value))
            .attr("fill", "#336699")

        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .selectAll("text")
                // .attr("x", "10")
                // .attr("transform", "rotate(-10)")
                // .style("text-anchor", "end");
        let yAxis = svg.append("g")
            .call(d3.axisLeft(y))

        yAxis.selectAll("text")
                .attr("x", "10")
                // .attr("transform", "rotate(-10)")
                .style("text-anchor", "start")
                .style("fill", "#000")
                .style("background-color", "#0f0")

        // yAxis.selectAll("g.tick").append("rect")
        //     .lower()
        //     .attr("y", "-5")
        //     .attr("width", "20")
        //     .attr("height", "10")
        //     .attr("fill", "#ffffff")
                // .exit()
            // .exit()
            // .selectAll("text")
            // .append("rect")
            //     .attr("width", 10)
            //     .attr("height", 10)
            //     .attr("fill", "#ffffff")
            // .select(this.parentNode)
            //     .append("rect")
            //     .attr("width", 10)
            //     .attr("height", 10)
            //     .attr("fill", "#ffffff")

            // .selectAll("rect").append("rect")
                // .attr("width", 10)
                // .attr("height", 10)
                // .attr("fill", "#ffffff")
            // .append("rect")
            //     .attr("width", 10)
            //     .attr("height", 10)
            //     .attr("fill", "#ffffff")
    }

}
